import gql from 'graphql-tag';

import SpendingPeriodFragment from './spendingPeriodFragment';

const CampaignFragment = gql`
  fragment CampaignFragment on Campaign {
    __typename
    id
    paused
    campaignTierID
    campaignName
    campaignID
    networkID
    agreed
    sidelineIntegration
    destinationErrorMessage
    destinations {
      destID
      agentID
      label
      destinationType
      destination
      uiID
    }
    priceTier {
      __typename
      id
      liveTransfers
      liveTransferPrice
      name
      dynamicPrice
      description
      price
      minPrice
      maxPrice
      priceTierID
      maxCampaignCount
      maxLeadCountPerDay
    }
    spendingPeriods {
      ...SpendingPeriodFragment
    }
    regions
    ageInt {
      min
      max
    }
    incomeInt {
      min
      max
    }
    householdInt {
      min
      max
    }
    priceFilterInt {
      min
      max
    }
  }
  ${SpendingPeriodFragment}
`;
export default CampaignFragment;
