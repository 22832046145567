import React, { ReactComponentElement, useMemo } from 'react';
import { Card, CardTitle, Row, Col, FormGroup, Label } from 'reactstrap';
import { SelectField } from '@availity/select';
import { usePriceTiers, useUser } from '@/hooks';
import { useField } from 'formik';
import { Field } from '@availity/form';
import { MoneyField } from '@/components';
import classNames from 'classnames';

const getFilter = (
  filterName: string,
  allowedStates: string[],
  type?: 'bulk' | 'campaign'
) => {
  switch (filterName) {
    case 'region':
      return (
        <Col key={filterName} xs={12}>
          <SelectField
            id={`${filterName}-dropdown`}
            name="regions"
            label="Regions"
            labelKey="label"
            valueKey="value"
            placeholder="Select a region"
            options={allowedStates.map((i: string) => ({
              label: i,
              value: i,
            }))}
            isMulti
            isClearable
          />
        </Col>
      );
    case 'ageInt':
    case 'householdInt':
    case 'incomeInt':
    case 'priceFilterInt':
      // eslint-disable-next-line no-case-declarations
      const Tag =
        filterName === 'incomeInt' || filterName === 'priceFilterInt'
          ? MoneyField
          : Field;
      return (
        <Col
          key={filterName}
          xs={6}
          className={classNames({ 'd-none': type === 'bulk' })}
        >
          <FormGroup>
            <Label>
              {filterName === 'priceFilterInt'
                ? 'Price'
                : filterName.charAt(0).toUpperCase() + filterName.slice(1)}{' '}
              Range
            </Label>
            <Row noGutters>
              <Col xs={5}>
                <Tag
                  id={`${filterName}-min-range`}
                  size="md"
                  name={`${filterName}.min`}
                  type="number"
                  placeholder="Min (0)"
                  convertToPennies={false}
                />
              </Col>
              <Col xs={5}>
                <Tag
                  id={`${filterName}-max-range`}
                  size="md"
                  name={`${filterName}.max`}
                  type="number"
                  placeholder="Max (0)"
                  convertToPennies={false}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      );
    default:
      return null;
  }
};

const FilterCard: React.FunctionComponent<{
  id?: string;
  type: 'campaign' | 'bulk';
}> = ({ type }) => {
  const { user } = useUser();
  const { data: tierData } = usePriceTiers();
  const [{ value }] = useField('priceTierID');

  const { isLiveTransfer, allowedFilters = [] } = useMemo(
    () => tierData?.tiers.find((tier: any) => tier.priceTierID === value) || {},
    [value, tierData]
  );

  if (isLiveTransfer || allowedFilters.length === 0) return null;

  return (
    <Card body className="mb-3">
      <CardTitle tag="h4" className="lead text-uppercase">
        Filters
      </CardTitle>
      <Row noGutters>
        {allowedFilters.map((f: string) =>
          getFilter(f, user?.network.allowedStates, type)
        )}
      </Row>
    </Card>
  );
};

export default FilterCard;
